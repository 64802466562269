import {
  API,
  CancelScheduledBreakPayload,
  ScheduleBreakPayload,
  UpdateEmployeeWorkStatusPayload,
  useCurrentEmployeeWorkStatus,
  useCuttersApi,
  useHairdresser,
} from '@hooks';

import { Config, Transaction, Product } from '@models';
import { urlWithParams } from '@utils/helpers';
import React from 'react';
import { useSWRConfig } from 'swr';

interface SetScheduledBreakModalParams {
  open: boolean;
  transactionId: string | null;
}

export type CuttersManager = {
  showSalonList: boolean;
  setShowSalonList: (showSalonList: boolean) => void;
  config: Config;
  setConfig: (config: any) => void;
  products: Product[];
  recommendedProducts: Product[];
  setProducts: (products: Product[]) => void;
  setRecommendedProducts: (products: Product[]) => void;
  takeBreak: (minutes: number) => void;
  stopBreak: () => void;
  showFinishHaircutModal: boolean;
  setShowFinishHaircutModal: (showFinishHaircutModal: boolean) => void;
  startWorkDay: (salonId: number) => void;
  stopWorkDay: () => Promise<UpdateEmployeeWorkStatusPayload | null | undefined>;
  scheduleBreakModal: any;
  setScheduleBreakModal: (params: SetScheduledBreakModalParams) => void;
  scheduleBreak: (payload: ScheduleBreakPayload) => Promise<void>;
  cancelScheduledBreak: (payload: CancelScheduledBreakPayload) => Promise<any>;
  lastCompletedTransaction: Transaction | null;
  setLastCompletedTransaction: (transaction: Transaction | null) => void;
  onOpenShiftModal: number;
  updateCachedTransactions: (transactions: Transaction[], customerId: string) => void;
  setOpenShiftModal: (value: number) => void;
};

export const useCuttersManager = (): CuttersManager => {
  const { mutate } = useSWRConfig();
  const [showSalonList, setShowSalonList] = React.useState<boolean>(false);
  const [config, setConfig] = React.useState<any>({});
  const [products, setProducts] = React.useState<Product[] | undefined>({});
  const [recommendedProducts, setRecommendedProducts] = React.useState<Product[] | undefined>({});
  const [showFinishHaircutModal, setShowFinishHaircutModal] = React.useState<boolean>(false);
  const [scheduleBreakModal, setScheduleBreakModal] = React.useState<any>({ open: false, transactionId: null });
  const [lastCompletedTransaction, setLastCompletedTransaction] = React.useState<Transaction | null>(null);
  const { apiUpdateWorkStatus, apiScheduleBreak, apiCancelScheduledBreak } = useCuttersApi();
  const [onOpenShiftModal, setOpenShiftModal] = React.useState<number>(0);

  const { workStatus } = useCurrentEmployeeWorkStatus();
  const { employee } = useHairdresser();

  const startWorkDay = async (salonId: number) => {
    const payload: UpdateEmployeeWorkStatusPayload = {
      employeeId: employee?.id,
      salonId: salonId,
      action: 'start_work',
    };

    const workStatus = await apiUpdateWorkStatus(payload);
    mutate(urlWithParams(API.GET_EMPLOYEE_WORK_STATUS, { id: employee?.id || '' }), workStatus, false);

    setTimeout(() => {
      setOpenShiftModal((prevState) => {
        return prevState + 1;
      });
    }, 20 * 1000);
  };

  const stopWorkDay = async (): Promise<UpdateEmployeeWorkStatusPayload | null | undefined> => {
    let payload: UpdateEmployeeWorkStatusPayload = {
      employeeId: employee?.id,
      since: new Date().toISOString(),
    };

    if (workStatus?.state === 'work') {
      payload.action = 'end_work';
      payload.salonId = workStatus?.salonId;
    }

    try {
      await apiUpdateWorkStatus(payload);
      return payload;
    } catch (error) {
      console.log(error);
    }
  };

  const takeBreak = async (minutes: number) => {
    const currentDate = new Date();

    const payload: UpdateEmployeeWorkStatusPayload = {
      employeeId: employee?.id,
      salonId: workStatus?.salonId,
      action: 'start_break',
      since: currentDate.toISOString(),
      until: new Date(currentDate.getTime() + minutes * 60000).toISOString(),
    };

    apiUpdateWorkStatus(payload).then((workStatus) => {
      mutate(`/workstatuses/employees/${employee?.id}`, workStatus, false);
    });
  };

  const stopBreak = async () => {
    const payload: UpdateEmployeeWorkStatusPayload = {
      employeeId: employee?.id,
      salonId: workStatus?.salonId,
      action: 'end_break',
      since: new Date().toISOString(),
    };

    apiUpdateWorkStatus(payload).then((workStatus) => {
      mutate(`/workstatuses/employees/${employee?.id}`, workStatus, false);
    });
  };

  const scheduleBreak = async (payload: ScheduleBreakPayload): Promise<void> => {
    return apiScheduleBreak(payload);
  };

  const cancelScheduledBreak = async (payload: CancelScheduledBreakPayload): Promise<void> => {
    return await apiCancelScheduledBreak(payload);
  };

  const updateCachedTransactions = async (transactions: Transaction[], customerId: string) => {
    mutate(`/transactions/customer/${customerId}?includeDetails=true`, transactions);
  };

  return {
    startWorkDay,
    stopWorkDay,
    takeBreak,
    stopBreak,
    config,
    setConfig,
    products,
    setProducts,
    recommendedProducts,
    setRecommendedProducts,
    showFinishHaircutModal,
    setShowFinishHaircutModal,
    lastCompletedTransaction,
    setLastCompletedTransaction,
    showSalonList,
    setShowSalonList,
    onOpenShiftModal,
    setOpenShiftModal,
    scheduleBreak,
    cancelScheduledBreak,
    scheduleBreakModal,
    setScheduleBreakModal,
    updateCachedTransactions,
  };
};
